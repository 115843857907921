import { globalEnvironment } from './global';

export const environment = {
  ...globalEnvironment,
  admin_tool_url: 'https://gw.cube.rina.org/admin-tool',
  api: {
    functions: 'https://gw.cube.rina.org/dias-ghg-func/test',
    grafana: 'https://grafana-e2e.azurewebsites.net',
  },
  b2cPolicies: {
    clientId: '33e5456b-20ca-4cf6-b504-2a82fc74bf6d',
    tenantId: 'ab37f843-3ed8-42e4-b104-6929068b10db',
    redirectUri: 'https://ghgprojects-test.cube.rina.org',
    redirectCourtesyPageUri: 'https://www.rina.org/',
    postLogoutRedirectUri: 'https://www.rina.org/',
    names: {
      signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
      resetPassword: 'B2C_1A_PASSWORDRESET',
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://login.rina.org/rinagroupb2c.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
      },
      resetPassword: {
        authority: 'https://login.rina.org/rinagroupb2c.onmicrosoft.com/B2C_1A_PASSWORDRESET',
      },
      editProfile: {
        authority: 'https://login.rina.org/rinagroupb2c.onmicrosoft.com/B2C_1A_PROFILEEDIT',
      },
    },
    domain: 'https://login.rina.org/',
    subdomain: 'rinagroupb2c.onmicrosoft.com',
    authorityDomain: 'https://login.rina.org/rinagroupb2c.onmicrosoft.com',
  },
  base_url: 'https://gw.cube.rina.org/dias-ghg-func/test',
  localStorageKeyPrefix: 'cube-dias-ghg-test',
};
